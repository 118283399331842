import React from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Title from "../../Base/Title";
import useStartMotion from "../../../hooks/use-start-motion";
import icons from "../../Header/icons-map";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const FeaturesWithIcons = ({ className, items, animations }) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`w-full relative
      bg-black-800 py-10 lg:py-36
      ${className}
      `}
      ref={ref}
    >
      <div
        className={`
          max-w-1440 mx-auto grid
          grid-cols-1 md:grid-cols-2 xl:grid-cols-4
          px-4 md:px-10 xl:px-40
          gap-x-12 xl:gap-x-8 gap-y-16
        `}
      >
        {items.map((item, idx) => (
          <motion.div
            className="feature-with-icon"
            key={item.title}
            animate={controls}
            initial={isMobileOnly || !animations ? "visible" : "hidden"}
            variants={slideBottomVariants}
            transition={{ delay: (idx + 1) * 0.7, duration: 0.8 }}
          >
            <div className="feature-icon-wrapper mb-6 w-6 h-auto">
              {icons.get(item.iconKey)}
            </div>
            <div
              className="text-black font-bold text-h4 mb-6"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <p className="text-p text-black-200">{item.subtext}</p>
          </motion.div>
        ))}
      </div>

      <style jsx global>{`
        .feature-icon-wrapper svg {
          width: 24px;
          height: 24px;
        }
      `}</style>
    </section>
  );
};

FeaturesWithIcons.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      iconKey: string,
      title: string,
      subtext: string,
    })
  ).isRequired,
  animations: bool,
};

FeaturesWithIcons.defaultProps = {
  className: "",
  animations: true,
};

export default FeaturesWithIcons;
